<template>
  <v-container fluid>
    <UserAuthForm
      v-if="user"
      ref="userAuthForm"
      type="ResetPassword"
      v-model="user"
      @submitForm="resetPassword"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import UserAuthForm from '@/components/auth/UserAuthForm';

export default {
  components: {
    UserAuthForm,
  },
  data() {
    return {
      user: null,
    };
  },
  methods: {
    resetPassword(user) {
      this.$store.dispatch('misc/setLoading', true);
      let token = this.$route.params.token;
      this.$store
        .dispatch('resetPassword', { user, token })
        .then(response => {
          this.$store.dispatch('misc/setLoading', false);
          var snack = {
            text: response.data.msg,
            color: 'success',
          };
          this.$store.dispatch('misc/setSnack', snack);
          this.reset();
          this.$router.push({ name: 'Login' });
        })
        .catch(error => {
          this.$store.dispatch('misc/setLoading', false);
          console.log(error);
          console.log(error.response.data.msg);
          var snack = {
            text: error.response.data.msg,
            color: 'error',
          };
          this.$store.dispatch('misc/setSnack', snack);
        });
    },
    reset() {
      this.$refs.userAuthForm.reset;
      this.user = this.emptyUser();
    },
  },
  computed: {
    ...mapGetters(['emptyUser']),
  },
  created() {
    let token = this.$route.params.token;
    // check if password reset token has not been used before
    this.$store
      .dispatch('getResetPasswordTokenValid', token)
      .then(response => {
        if (!response.data.valid) {
          this.$store.dispatch('misc/setLoading', false);
          var snack = {
            text: response.data.msg,
            color: 'error',
          };
          this.$store.dispatch('misc/setSnack', snack);
          this.reset();
          this.$router.push({ name: 'Login' });
        }
      })
      .catch(error => {
        this.$store.dispatch('misc/setLoading', false);
        console.log(error);
        console.log(error.response.data.msg);
        var snack = {
          text: error.response.data.msg,
          color: 'error',
        };
        this.$router.push({ name: 'Login' });
      });
  },
  mounted() {
    this.user = this.emptyUser();
  },
};
</script>

<style></style>
